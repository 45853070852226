.login-brand {
  font-size: 72px;
}

.login-container {
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  color: rgb(40, 75, 87);
}

@media only screen and (max-width: 767px) {
  .login-brand {
    display: none;
  }

  .login-container {
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .login-brand {
    font-size: 48px;
  }

  .login-container {
    justify-content: center;
  }
}

@media only screen and (max-width: 767.98px) {
  .p-dialog-mask .p-dialog .contacts-section {
    margin-top: 50px;
    padding-top: 30px;
    border-top: 1px solid #cccccc;
  }
}

@media only screen and (min-width: 768px) {
  .p-dialog-mask .p-dialog .contacts-section {
    padding-left: 20px;
    border-left: 1px solid #cccccc;
  }
}

.pic-upload-container {
  outline: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: 72px;
}

.pic-upload-container::before {
  display: none;
}

.pic-upload-container:hover::before {
  display: inline;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #00000077;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 2.25;
  font-size: 32px;
  color: #ffffff;
  z-index: 1;
  font-weight: 700;
}

.pic-upload-container .profile-pic-placeholder {
  width: 72px;
  height: 72px;
  display: inline-block;
  position: relative;
  background-color: #74788d;
  border-radius: 50%;
}

.pic-upload-container .profile-pic-placeholder i {
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff !important;
}

/* Add or Remove a Field Icon */
.add-or-remove-icon-container {
  text-align: center;
  line-height: 1.8;
  font-size: 24px;
  margin-top: 15px;
}

.add-or-remove-icon-container i {
  cursor: pointer;
  position: relative;
  top: 6px;
}

.add-or-remove-icon-container i:hover {
  background-color: #49505738;
  border-radius: 50%;
  padding: 2px;
}

/* multiselect options */
.multiselect-option-item {
  display: flex;
  line-height: 1;
  margin-left: 5px;
  align-items: center;
}

.multiselect-option-item div {
  margin-left: 10px;
}

/* Icon panel */
.icon-panel {
  text-align: center;
  display: inline-block;
  margin-right: 30px;
}

/* OTP Input field */
.otp-input-field {
  width: 30px;
  margin-right: 20px;
}

.otp-input-field input {
  width: 100% !important;
  border: 0;
  border-bottom: 1px solid rgb(133, 133, 133);
}

/* Lightbox overlay */
.ReactModal__Overlay {
  z-index: 10000 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Projects card in modal of Contacts page */
.contacts-project-card {
  min-height: 200px;
  box-shadow: 0px 1px 7px #00000038;
  cursor: pointer;
}

.contacts-project-card:hover {
  transform: scale(1.02);
}

/* lead status breadcrumb */
.lead-status-breadcrumb {
  border: none !important;
}

.lead-status-breadcrumb.p-breadcrumb ul li.p-breadcrumb-chevron {
  display: none;
}

.lead-status-breadcrumb.p-breadcrumb ul li .p-menuitem-link {
  padding: 0 15px 4px;
  background-color: #74788d;
  border-radius: 0;
}

.lead-status-breadcrumb.p-breadcrumb ul li a.p-menuitem-link:focus {
  box-shadow: none;
}

.lead-status-breadcrumb.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #ffffff;
  font-size: 13px;
  position: relative;
}

.lead-status-breadcrumb.p-breadcrumb
  ul
  li
  .p-menuitem-link
  .p-menuitem-text::after {
  content: '';
  border-top: 13px solid #ffffff;
  border-right: 0 solid transparent;
  border-bottom: 12px solid #ffffff;
  border-left: 15px solid #74788d;
  position: absolute;
  top: -3px;
}

.lead-status-breadcrumb.p-breadcrumb
  ul
  li
  .p-menuitem-link
  .p-menuitem-text::before {
  content: '';
  border-top: 13px solid #74788d;
  border-right: 0 solid transparent;
  border-bottom: 12px solid #74788d;
  border-left: 14px solid transparent;
  position: absolute;
  top: -3px;
  left: -28px;
}

.lead-status-breadcrumb.p-breadcrumb ul li .p-menuitem-link.selected {
  background-color: #005fad;
}

.lead-status-breadcrumb.p-breadcrumb
  ul
  li
  .p-menuitem-link.selected
  .p-menuitem-text::after {
  content: '';
  border-top: 13px solid #ffffff;
  border-right: 0 solid transparent;
  border-bottom: 12px solid #ffffff;
  border-left: 15px solid #005fad;
  position: absolute;
  top: -3px;
}

.lead-status-breadcrumb.p-breadcrumb
  ul
  li
  .p-menuitem-link.selected
  .p-menuitem-text::before {
  content: '';
  border-top: 13px solid #005fad;
  border-right: 0 solid transparent;
  border-bottom: 12px solid #005fad;
  border-left: 14px solid transparent;
  position: absolute;
  top: -3px;
  left: -28px;
}
