/* Form elements */
.p-field label {
    margin-top: 15px;
}

label[required]::after {
    content: '*';
    color: red;
}

input[type=number].p-inputtext::-webkit-inner-spin-button, input[type=number].p-inputtext::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}


/* Dialog modal */
.p-dialog-mask.p-component-overlay {
    z-index: 10000 !important;
    overflow-y: auto;
}

.p-dialog-mask.p-component-overlay .p-dialog {
    overflow-y: visible;
}

.p-dialog-mask.p-component-overlay .p-field label {
    margin-top: 15px;
}

.p-dialog-mask.p-component-overlay .p-dialog-content {
    overflow-y: visible;
}

/* primereact data table */
.p-toolbar-group-left .p-input-icon-left > i:first-of-type {
    font-size: 13px;
    top: 60%;
}

.p-toolbar-group-left .p-input-icon-right > i:first-of-type {
    top: 55%;
}

.p-toolbar-group-left .p-input-icon-left > .p-inputtext, .p-toolbar-group-left .p-input-icon-right > .p-inputtext {
    font-size: 13px;
    height: 32px;
}

.p-toolbar-group-right .p-button.p-component {
    padding: 8px 10px;
    height: 32px;
}

.p-toolbar-group-right .p-button.p-component > .p-button-icon-left {
    font-size: 12px;
}

.p-toolbar-group-right .p-button.p-component > .p-button-label {
    font-size: 13px;
}
.datatable-crud-demo {
    font-family: "Poppins",sans-serif;

}

.p-datatable .p-datatable-wrapper {
    overflow-x: auto;
}

/* width */
.p-datatable .p-datatable-wrapper::-webkit-scrollbar {
    height: 4px;
}
  
/* Track */
.p-datatable .p-datatable-wrapper::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.19);
    border-radius: 12px;
}
   
/* Handle */
.p-datatable .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background: #afafaf; 
    border-radius: 12px;
}
  
/* Handle on hover */
.p-datatable .p-datatable-wrapper::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.p-datatable table {
    font-family: "Poppins",sans-serif;
    min-width: 100%;
    width: auto;
}

@media only screen and (min-width: 992px) {
    .p-datatable table thead {
        border-bottom: 2px solid #00000015;
    }
}

.p-datatable table .p-datatable-thead > tr > th {
    white-space: nowrap;
}

.p-datatable .p-column-title, .p-datatable .p-sortable-column .p-column-title, .p-datatable .p-datatable-tbody > tr > td {
    font-size: 13px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    font-size: 12px;
}

.p-datatable .p-datatable-tbody > tr > td {
    overflow-wrap: initial;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}

.p-datatable .p-datatable-tbody > tr > td button.p-button {
    height: 25px;
    width: 25px;
    margin-right: 10px;
}

.p-datatable .p-datatable-tbody > tr > td button.p-button span {
    font-size: 13px;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-emptymessage > td {
    text-align: center;
}

.p-paginator {
    font-family: "Poppins",sans-serif;
    font-size: 13px;
}

.p-paginator .p-link {
    font-family: "Poppins",sans-serif;
}

.p-paginator .p-paginator-pages button {
    min-width: 32px !important;
    height: 32px !important;
}

.p-paginator .p-paginator-pages button.p-paginator-page {
    border-radius: 50% !important;
    border: none;
}

.p-paginator .p-paginator-pages button.p-paginator-page.p-highlight {
    color: #fff;
    background-color: #556ee6;
}

.p-paginator .p-inputtext {
    font-family: "Poppins",sans-serif;
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    height: 16px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    height: 30px;
    min-width: 30px;
}

.p-paginator .p-dropdown {
    height: 30px;
}

.p-paginator .p-dropdown .p-dropdown-label {
    font-size: 13px;
    line-height: 1;
}

.p-dropdown .p-dropdown-trigger {
    width: 30px;
}

.p-dropdown .p-dropdown-trigger span.p-dropdown-trigger-icon {
    font-size: 13px;
    position: relative;
    top: 1px;
}

.Toastify__toast-container {
 z-index: 200000 !important;
}

#preloader {
    opacity: 0.5 !important;
}

.chase-dot:before {
    background-color: #015eac !important;
}

/* Slider - prime react */
.p-slider-horizontal {
    width: 14rem;
}